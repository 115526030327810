.hero-container{
    background: url('../image/sunset\ waves.webp') center center/cover no-repeat;
    /* background-color: black; */
    filter: grayscale(100%);
    width: 100%;
    height: 100vh;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    animation-name: color-in;
    animation-duration: .8s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}
@keyframes color-in{
    from{
        filter: grayscale(100%);
    }
    to{
        filter: grayscale(0);
    }
}
video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: fixed;
    z-index: -1;
}

.hero-container  h1,
.hero-container  h2,
.hero-container  p {
    color: var(--color-primary);
    text-shadow: 3px 3px 3px rgb(0,0,0,.5);
}

.btn-container{
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.btn-styling{
    border: 1px solid white;
    border-radius: 3px;
    padding: .5rem 2rem;
    margin-bottom: 1rem;
    font-size:1.2rem;
    font-weight: 600;
    text-decoration: none;
    transition: all .4s ease-in-out;
}
.hero-btn:link,
.hero-btn:visited {
    text-decoration: none;
    color: var(--color-primary);
}
.hero-btn:hover{
    background-color: var(--color-primary);
    color: var(--color-black);
}
.hero-btn > i{
    color: var(--color-primary);
    margin: 0 0 0 1rem;
    border-radius: 50%;
    transition: all .4s ease-in-out;
    background:transparent;
}
.hero-btn:hover > i {
    color: var(--color-black);
    border-color: var(--color-black);
}
.hero-btn-reverse:link,
.hero-btn-reverse:visited{
    text-decoration: none;
    color: var(--color-black);
}
.hero-btn-reverse{
    color: var(--color-black);
    background-color: var(--color-primary);
    
}
.hero-btn-reverse > i {
    color: var(--color-black);
    margin: 0 0 0 1rem;
    border-radius: 50%;
    transition: all .4s ease-in-out;
    background:transparent;
}
.hero-btn-reverse:hover{
    background-color: transparent;
    color: var(--color-primary);
}
.hero-btn-reverse:hover > i {
    color: var(--color-primary);
    border-color: var(--color-primary);
}

.home-container{
    color: var(--color-black);
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    /* gap: 3rem; */
    align-items: center;
    justify-content: center;
    /* padding: 5rem 0; */
    margin: 5rem 0;
}
.skill-list{
    list-style: none;
    display: flex;
    flex-direction: column;
    position: relative;
    /* border: 1px solid black; */
}
.skill-container{
    display: inline-block;
    margin-bottom: 0.5rem;
}

.skill-list-item{
    position: relative;
    transform: translateX(-50vw);
    opacity: 0;
    transition: all 1s ease-out calc(.4s*var(--i));
    border: 1px solid black;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
    text-align: center;
    display: inline-block;
    font-family: 'Righteous', cursive;
    box-shadow: 5px 5px 5px rgb(0,0,0,0.5);
}

.skill-list.shown .primary-skill{
    transform: translate(0);
    opacity: 1;
}
.primary-skill {
    width: 20rem;
    color: var(--color-primary);
    z-index: 2;
}
.secondary-skill {
    width: fit-content;
    margin-left: .5rem;
    transform: translateX(-50vw);
    opacity: 0;
    color: var(--color-black);
    z-index: 1;
}
.skill-list.shown .secondary-skill{
    transform: translate(0);
    opacity: 1;
}

.home-container h1 {
    text-shadow: 2px 2px 2px rgb(0,0,0,.5);
    margin-bottom: 5rem;
    
}
